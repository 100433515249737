import PropTypes from 'prop-types'
import React from 'react'

const Article = ({ markdownRemark }) => {
  const { html } = markdownRemark
  return (
    <div className="columns">
      <div className="column is-three-quarters">
        <div
          className="content has-text-justified"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <div className="column">
        <div className="tags" />
      </div>
    </div>
  )
}

Article.propTypes = {
  markdownRemark: PropTypes.any,
}

export default Article
