import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'
import Article from '../components/article'

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, fields } = markdownRemark
  const pageTitle = frontmatter.title
  return (
    <Layout pageTitle={pageTitle}>
      <SEO title={pageTitle} />
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/posts">Posts</Link>
          </li>
          <li className="is-active">
            <a href={fields.path} aria-current="page">
              {fields.date}
            </a>
          </li>
        </ul>
      </nav>
      <Article markdownRemark={markdownRemark} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      html
      frontmatter {
        title
      }
      fields {
        path
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
